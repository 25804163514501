import http from "../http-common";
const axios = require('axios');

class MemberDataService {
  getAll() {
    return http.get("/api/members");
  }

  get(id) {
    return http.get(`/api/members/${id}`);
  }

  create(data) {
    return http.post("/api/members", data);
  }

  update(id, updateType, data) {
    return http.put(`/api/members/${id}/${updateType}`, data);
  }

  updateUser(id, data) {
    return http.put(`/api/users/${id}`, data);
  }

  delete(id) {
    return http.delete(`/api/members/${id}`);
  }

  deleteAll() {
    return http.delete(`/api/members`);
  }

  findByNameDate(data) {
    return http.post(`/api/members/findbyNameDate`, data);
  }

  register(data) {
    return http.post("/api/users", data);
  }

  updateRegister(id, data) {
    return http.put(`/api/users/${id}`, data);
  }

  userCheck(data) {
    return http.post(`/api/users/userCheck`, data);
  }

  validateLogin(data) {
    return http.post(`/api/login`, data);
  }

  loginCounter(data) {
    return http.post(`/api/users/loginCounter`, data);
  }

  emailSent(data) {
    return http.post('/api/users/emailSent', data);
  }

  findId(data) {
    return http.post('/api/users/findId', data);
  }

  findPassword(data) {
    return http.post('/api/users/findPassword', data);
  }

  validateAdminLogin(data) {
    return http.post('/api/adminlogin', data);
    // return axios.post(`/api/adminlogin`, data).then(r=>{
    //   console.log(r);
    // }).catch(e=>{
    //   console.log(e);
    // });
  }

  logoutAdmin() {
    return http.get("/api/logout");
  }
}

export default new MemberDataService();