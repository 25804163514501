<template>
  <div>
    <base-card>
      <v-container fluid>
        <div class="row content">
          <div class="col-sm-12 col-md-12 col-xl-12 xol-lg-12 admin_dashbord_content">
            <v-dialog
              v-model="licenseNumberalreadyExistModal"
              width="500"
            >
              <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                  <center>{{ $t("memberList.warningforlicenseNumberAlreadyExist") }}</center>
                </v-card-title>
                <br>
                <v-card-text style="color: black;font-size: 18px;">
                  <center>{{ $t("memberList.licenseNumberAlreadyExist") }}</center>
                </v-card-text>

                <v-divider />

                <center>
                  <v-card-actions style="display: block;">
                    <v-spacer />

                    <v-btn
                      color="primary"
                      @click="licenseNumberalreadyExistModal = false"
                    >
                      {{ $t("memberList.close") }}
                    </v-btn>
                  </v-card-actions>
                </center>
              </v-card>
            </v-dialog>

            <h5 class="fw-700">
              {{ $t("memberList.memberList") }}
            </h5>
            <v-row class="admin_dashbord_content_member_list_row_first">
              <v-col
                class="admin_dashbord_content_member_list"
                cols="12"
                md="3"
                sm="6"
              >
                <v-menu
                  v-model="startMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template
                    v-slot:activator="{ on, attrs }"
                    outlined
                  >
                    <v-text-field
                      v-model="startDate"
                      label=""
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      outlined
                      style="min-height: auto;"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="startDate"
                    @input="startMenu = false"
                  />
                </v-menu>
              </v-col>
              <v-col
                class="admin_dashbord_content_member_list"
                cols="12"
                md="3"
                sm="6"
              >
                <v-menu
                  v-model="endMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="endDate"
                      label=""
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      outlined
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="endDate"
                    @input="endMenu = false"
                  />
                </v-menu>
              </v-col>
              <v-col
                cols="12"
                md="6"
                sm="12"
              >
                <div
                  class="date_time_week_btngroup"
                  aria-label="Basic outlined example"
                >
                  <v-btn-toggle
                    v-model="toggle_exclusive"
                    class="date_time_week_btngroup_toggle"
                    mandatory
                  >
                    <v-btn
                      type="button"
                      class="date_time_week_btngroup_button btn btn-outline-secondary"
                      @click="filterDate('today')"
                    >
                      {{
                        $t("memberList.today")
                      }}
                    </v-btn>
                    <v-btn
                      type="button"
                      class="date_time_week_btngroup_button btn btn-outline-secondary"
                      @click="filterDate('1week')"
                    >
                      {{
                        $t("memberList.week")
                      }}
                    </v-btn>
                    <v-btn
                      type="button"
                      class="date_time_week_btngroup_button btn btn-outline-secondary"
                      @click="filterDate('1month')"
                    >
                      {{
                        $t("memberList.onemonth")
                      }}
                    </v-btn>
                    <v-btn
                      type="button"
                      class="date_time_week_btngroup_button btn btn-outline-secondary"
                      @click="filterDate('6month')"
                    >
                      {{
                        $t("memberList.sixmonth")
                      }}
                    </v-btn>
                    <v-btn
                      type="button"
                      class="date_time_week_btngroup_button btn btn-outline-secondary"
                      @click="filterDate('1year')"
                    >
                      {{
                        $t("memberList.year")
                      }}
                    </v-btn>
                    <v-btn
                      type="button"
                      class="date_time_week_btngroup_button btn btn-outline-secondary"
                      @click="filterDate('all')"
                    >
                      {{
                        $t("memberList.all")
                      }}
                    </v-btn>
                  </v-btn-toggle>
                </div>
              </v-col>
            </v-row>

            <v-row class="admin_dashbord_content_member_list_row">
              <v-col
                cols="12"
                md="5"
                sm="6"
                class="admin_dashbord_content_member_list"
              >
                <v-text-field
                  v-model="inputName"
                  class="member_text_field_slot"
                  outlined
                  :placeholder="$t('memberList.memberName')"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
                sm="6"
              >
                <!-- Removed coz Suggestion -->
                <!-- <v-btn outlined class="btn btn-outline-secondary mr-1 w-25" @click="searchNameDate">{{
                    $t("memberList.Inquiry")
                }}</v-btn> -->
                <v-btn
                  color="primary"
                  class="btn btn-outline-secondary mr-1 w-25"
                  @click="searchNameDate"
                >
                  {{
                    $t("memberList.Inquiry")
                  }}
                </v-btn>
                <!-- Removed coz Suggestion -->
                <!-- <v-btn outlined class="btn btn-outline-secondary w-25" @click="refreshListWithDateInput">{{
                    $t("memberList.reset")
                }}</v-btn> -->
                <v-btn
                  class="btn btn-outline-secondary w-25 "
                  style="background-color:#ff0000; color: white; "
                  @click="refreshListWithDateInput"
                >
                  {{
                    $t("memberList.reset")
                  }}
                </v-btn>
              </v-col>
            </v-row>

            <div class="datacountClass">
              {{ $t("memberList.search") }}: {{ datatableCount }}
            </div><br>
            <v-data-table
              :headers="headers"
              :items="members"
              :items-per-page="10"
              class="elevation-1"
              dense
            >
              <template v-slot:[`item.viewLicense`]="{ item }">
                <!-- <v-icon small class="mr-2" @click="editMember(item.id)">mdi-pencil</v-icon> -->
                <a @click="editMember(item.id, 'viewLicense')"><img
                  height="25px"
                  width="25px"
                  class="datatable_image_icon ms-3"
                  src="@/assets/images/customImages/logo1.png"
                ></a>
                <!-- <v-icon small @click="deleteMember(item.id)">mdi-delete</v-icon> -->
              </template>
              <template v-slot:[`item.viewdetails`]="{ item }">
                <!-- <v-icon small class="mr-2" @click="editMember(item.id)">mdi-pencil</v-icon> -->
                <a @click="editMember(item.id, 'viewdetails')"><img
                  height="25px"
                  width="25px"
                  class="datatable_image_icon"
                  src="@/assets/images/customImages/Search_icon.png"
                ></a>
                <!-- <v-icon small @click="deleteMember(item.id)">mdi-delete</v-icon> -->
              </template>
            </v-data-table>
            <v-dialog
              v-if="currentMember"
              v-model="dialog"
              class="v_dialog_member"
              persistent
              max-width="950px"
              scrollable
            >
              <!-- <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark v-bind="attrs" v-on="on">
                Open Dialog
              </v-btn>
            </template> -->
              <v-card class="v_dialog_member">
                <v-card-title>
                  <span class="text-h5">{{ $t(dialogTitle) }}</span>
                </v-card-title>
                <hr>
                <br>
                <v-card-text class="v_dialog_member">
                  <v-container
                    v-if="dialogText === 'viewDetailContainer'"
                    class="v_dialog_member"
                  >
                    <v-form
                      ref="form"
                      v-model="validDetails"
                      lazy-validation
                    >
                      <v-row class="dialog_member_row_test">
                        <v-col
                          cols="12"
                          sm="6"
                          md="4"
                        >
                          {{ $t("memberList.name") }}
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="6"
                        >
                          <v-text-field
                            v-model="currentMember.name"
                            :rules="nameRules"
                            outlined
                            required
                          />
                        </v-col>
                      </v-row>
                      <v-row class="dialog_member_row_test">
                        <v-col
                          cols="12"
                          sm="6"
                          md="4"
                        >
                          {{ $t("memberList.email") }}
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="6"
                        >
                          <v-text-field
                            v-model="currentMember.email"
                            class="email_read_only_modal"
                            outlined
                            readonly
                          />
                        </v-col>
                      </v-row>
                      <v-row class="dialog_member_row_test">
                        <v-col
                          cols="12"
                          sm="6"
                          md="4"
                        >
                          {{ $t("memberList.password") }}
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="6"
                        >
                          <v-text-field
                            v-model="currentMember.password"
                            :rules="passwordRules"
                            outlined
                            required
                          />
                        </v-col>
                      </v-row>
                      <v-row class="dialog_member_row_test">
                        <v-col
                          cols="12"
                          sm="6"
                          md="4"
                        >
                          {{ $t("memberList.dateofBirth") }}
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="2"
                        >
                          <v-text-field
                            v-model="birthDate.year"
                            :rules="yearRules"
                            outlined
                            required
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="2"
                        >
                          <v-select
                            v-model="birthDate.month"
                            :items="months"
                            outlined
                            required
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="2"
                        >
                          <v-text-field
                            v-model="birthDate.date"
                            :rules="dayRules"
                            outlined
                            required
                          />
                        </v-col>
                      </v-row>
                      <v-row class="dialog_member_row_test">
                        <v-col
                          cols="12"
                          sm="6"
                          md="4"
                        >
                          {{ $t("memberList.licenseType") }}
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="6"
                        >
                          <v-select
                            v-model="currentMember.licenseType"
                            outlined
                            :items="licenseTypes"
                            required
                          />
                        </v-col>
                      </v-row>
                      <v-row class="dialog_member_row_test">
                        <v-col
                          cols="12"
                          sm="6"
                          md="4"
                        >
                          {{ $t("memberList.licenseNumber") }}
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="6"
                        >
                          <v-text-field
                            v-model="currentMember.licenseNumber"
                            :rules="licenseNumberRules"
                            outlined
                            required
                          />
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-container>
                  <v-container
                    v-else-if="dialogText === 'viewLicenseContainer'"
                    class="v_dialog_member"
                  >
                    <div class="modal-body view_license_ul ">
                      <div class="d-inline-block">
                        <ul class="">
                          <li>{{ $t("memberList.name") }}</li>
                          <li>{{ $t("memberList.licenseType") }}</li>
                          <li>{{ $t("memberList.licenseNumber") }}</li>
                          <li>{{ $t("memberList.dateofBirth") }}</li>
                        </ul>
                      </div>
                      <div class="d-inline-block">
                        <ul class="name_details_span">
                          <li><span>{{ currentMember.name }}</span></li>
                          <li><span>{{ currentMember.licenseType }}</span></li>
                          <li><span>{{ currentMember.licenseNumber }}</span></li>
                          <li>
                            <span>{{ currentMember.birth.split('-')[0] + "." + currentMember.birth.split('-')[1] + "."
                              +
                              currentMember.birth.split('-')[2]
                            }}</span>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <!-- <ul>
                      <li>
                        {{ $t("memberList.name") }} </li>
                    </ul>
                    <ul>
                      <li>{{ currentMember.name }}</li>
                    </ul>

                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <ul>
                          <li>
                            {{ $t("memberList.licenseType") }}</li>
                        </ul>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        {{ currentMember.licenseType }}
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <ul>
                          <li>
                            {{ $t("memberList.licenseNumber") }}</li>
                        </ul>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        {{ currentMember.licenseNumber }}
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <ul>
                          <li>
                            {{ $t("memberList.dateofBirth") }}</li>
                        </ul>
                      </v-col>
                      <v-col cols="12" sm="6" md="2">
                        {{ currentMember.birth.split('-')[0] + "." + currentMember.birth.split('-')[1] + "." +
                            currentMember.birth.split('-')[2]
                        }}
                      </v-col>
                    </v-row> -->
                    <br>
                    <!-- <hr /> -->
                    <br>
                    <ul class="view_license_dialog list-group list-group-horizontal">
                      {{ $t("memberList.approvalStatus") }}
                      <br><br>
                      <v-btn-toggle
                        v-model="currentMember.hcpApproval"
                        class="approval_toggle_button"
                        mandatory
                      >
                        <v-btn value="Waiting for approval">
                          {{
                            $t("memberList.waitingforapproval")
                          }}
                        </v-btn>
                        <v-btn value="Approved">
                          {{ $t("memberList.approved")
                          }}
                        </v-btn>
                        <v-btn value="Name discrepancy">
                          {{
                            $t("memberList.nameDiscrepancy")
                          }}
                        </v-btn>
                        <v-btn value="License number mismatch">
                          {{
                            $t("memberList.licenseNumberMismatch")
                          }}
                        </v-btn>
                        <v-btn value="Birth date mismatch">
                          {{
                            $t("memberList.birthDateMismatch")
                          }}
                        </v-btn>
                      </v-btn-toggle>
                    </ul>
                    <!-- <div class="view_license_main_footer">
                  <v-btn type="button" style="background-color:#D9D9D9;width: 140px;" @click="updateMember">{{ $t("memberList.save") }}</v-btn>
                  <v-btn type="button" style="background-color:#D9D9D9;width: 140px;margin-left: 10px;" @click="dialog = false">{{ $t("memberList.close") }}</v-btn>
                </div> -->
                    <!-- <div class="view_license_main_footer modal-footer">
                      <button type="button" class="btn btn-secondary">Save</button>
                      <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    </div> -->
                    <!--
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <ul>
                          <li>
                            {{ $t("memberList.approvalStatus") }}
                          </li>
                        </ul>
                      </v-col>
                      <v-col cols="12" sm="6" md="8">
                        <ul class="view_license_dialog list-group list-group-horizontal">
                          <v-btn-toggle class="approval_toggle_button" v-model="currentMember.hcpApproval" mandatory>

                              <v-btn style="font-size: 14px !important;font-weight: 400px !important;"
                                value="Waiting for approval">
                                {{ $t("memberList.waitingforapproval") }}
                              </v-btn>
                            </li>

                              <v-btn style="font-size: 14px !important;font-weight: 400px !important;" value="Approved">
                                {{ $t("memberList.approved") }}
                              </v-btn>
                            </li>

                              <v-btn style="font-size: 14px !important;font-weight: 400px !important;"
                                value="Name discrepancy">
                                {{ $t("memberList.nameDiscrepancy") }}
                              </v-btn>
                            </li>

                              <v-btn style="font-size: 14px !important;font-weight: 400px !important;"
                                value="License number mismatch">
                                {{ $t("memberList.licenseNumberMismatch") }}
                              </v-btn>
                            </li>

                              <v-btn style="font-size: 14px !important;font-weight: 400px !important;"
                                value="Birth date mismatch">
                                {{ $t("memberList.birthDateMismatch") }}
                              </v-btn>
                            </li>
                          </v-btn-toggle>
                        </ul>
                      </v-col>
                    </v-row> -->
                  </v-container>
                </v-card-text>

                <div
                  v-if="viewDetailDeleteButtonShow"
                  style="align-items: center !important;text-align: center !important;margin-bottom: 20px;"
                >
                  <v-btn
                    type="button"
                    color="primary"
                    style="width: 140px;"
                    @click="updateMember"
                  >
                    {{
                      $t("memberList.save")
                    }}
                  </v-btn>
                  <v-btn
                    type="button"
                    color="primary"
                    style="width: 140px;margin-left: 10px;color: white;"
                    @click="dialog = false"
                  >
                    {{
                      $t("memberList.close")
                    }}
                  </v-btn>

                  <!-- <v-card-actions class="vcardaction_buttons">
                    <v-btn color="black" style="background-color:#D9D9D9;width: 140px;" text @click="updateMember">
                      {{ $t("memberList.save") }}
                    </v-btn>

                  <v-btn color="black" style="background-color:#D9D9D9;" text @click="deleteMember">
                    {{ ("memberList.delete") }}
                  </v-btn> -->
                  <!-- <v-btn color="black" style="background-color:#D9D9D9;width: 140px;" text @click="dialog = false">
                    {{ $t("memberList.close") }}
                  </v-btn>
                  </v-card-actions> -->
                </div>
                <div
                  v-else-if="!viewDetailDeleteButtonShow"
                  style="align-items: center !important;text-align: center !important;margin-bottom: 20px;"
                >
                  <v-btn
                    type="button"
                    color="primary"
                    style="width: 140px;color: white;"
                    @click="updateMember"
                  >
                    {{ $t("memberList.save") }}
                  </v-btn>

                  <v-btn
                    type="button"
                    style="background-color:#ff0000; color: white; width: 140px;margin-left: 10px;"
                    @click="deleteMember"
                  >
                    {{ $t("memberList.delete") }}
                  </v-btn>
                  <v-btn
                    type="button"
                    color="primary"
                    style="width: 140px;margin-left: 10px; color: white;"
                    class="view_details_close_btn"
                    @click="dialog = false"
                  >
                    {{ $t("memberList.close") }}
                  </v-btn>
                  <!-- <v-card-actions class="vcardaction_buttons_withdelete">
                    <v-btn color="black" style="background-color:#D9D9D9;width: 140px;" text @click="updateMember">
                      {{ $t("memberList.save") }}
                    </v-btn>

                    <v-btn color="black" style="background-color:#D9D9D9;width: 140px;" text @click="deleteMember">
                      {{ $t("memberList.delete") }}
                    </v-btn>
                    <v-btn color="black" style="background-color:#D9D9D9;width: 140px;" text @click="dialog = false">
                      {{ $t("memberList.close") }}
                    </v-btn>
                  </v-card-actions> -->
                </div>
              </v-card>
            </v-dialog>
          </div>
        </div>
      </v-container>
    </base-card>
  </div>
</template>

<script>
  import '@/assets/css/style.css'
  import '@/assets/jquery/jquery.min.js'
  import '@/assets/google-font/stylesheet.css'
  import MemberDataService from '@/services/MemberDataService'
  // import Sidebar from '@/layouts/vertical-white/View.vue'
  export default {
    name: 'MembersList',
    // components: {
    //    Sidebar
    // },
    data () {
      return {
        members: [],
        title: '',
        headers: [
          { text: 'Id', align: ' d-none', value: 'id' },
          { text: 'Key', align: 'start', sortable: false, value: 'key' },
          { text: 'Name', value: 'name', sortable: false },
          { text: 'E-mail', value: 'email', sortable: false },
          { text: 'Approval status', value: 'approvalStatus', sortable: false },
          { text: 'View License', value: 'viewLicense', sortable: false },
          { text: 'View details', value: 'viewdetails', sortable: false },
        ],
        validDetails: true,
        licenseNumberalreadyExistModal: false,
        viewDetailDeleteButtonShow: true,
        memberPageSwap: 'memberList',
        datatableCount: '',
        totalSubscribers: '',
        todaysSubscribers: '',
        filteredSubscribers: '',
        totalLogins: '',
        todaysLogins: '',
        filteredLogins: '',
        inputName: '',
        filterDateText: '',
        filterDateTextforStatistics: '',
        filterDateTextforall: '',
        filterDateTextforallStatistics: '',
        startDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        endDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        startMenu: false,
        endMenu: false,
        id: null,
        currentMember: null,
        birthDate: {},
        message: '',
        licenseNumber: '',
        dialog: false,
        dialogTitle: '',
        dialogText: '',
        dialogButtonText: '',
        licenseTypes: [
          'Doctor',
          'Nurse',
          'Pharmacist',
        ],
        months: [
          '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12',
        ],
      }
    },
    computed: {
      nameRules () {
        return [v => v.length <= 5 || this.$t('memberList.nameRulesMessage')]
      },
      emailRules () {
        return [v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || this.$t('memberList.emailRulesMessage')]
      },
      passwordRules () {
        return [v => !v || /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,50}$/.test(v) || this.$t('memberList.passwordRulesMessage')]
      },
      // confirmpasswordRules() {
      //   return [((this.reverse(this.user.password)) === (this.reverse(this.user.confirmPassword))) || this.$t('memberList.confirmpasswordRulesMessage')];
      // },
      yearRules () {
        return [v => !v || /^[0-9]{4}$/.test(v) || this.$t('memberList.yearRulesMessage')]
      },
      dayRules () {
        return [v => /^[0-9]{0,2}$/.test(v) || this.$t('memberList.dayRulesMessagefortwodigits'), v => v < 32 || this.$t('memberList.dayRulesMessageformustbemonth')]
      },
      licenseNumberRules () {
        return [v => v.length <= 20 || this.$t('memberList.licenseNumberRulesMessage')]
      },

    },
    mounted () {
      this.retrieveMembers()
    // this.message = "";
    // this.getMember(this.$route.params.id);
    },
    methods: {
      retrieveMembers () {
        MemberDataService.getAll()
          .then((response) => {
            console.log(response.data.length)
            this.datatableCount = response.data.length
            this.members = response.data.map(this.getDisplayMember)

            var data = {
              startDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
              endDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            }
            console.log('Start Date : ' + JSON.stringify(data))
            MemberDataService.findByNameDate(data)
              .then((response) => {
                console.log(response.data)
              // this.datatableCount = response.data.length
              // console.log(this.datatableCount)
              })
              .catch((e) => {
                console.log('Error is : ' + e)
                // if (e.response.status === 401) {
                //  console.log("Unauthorized")
                //  localStorage.clear();
                //  this.$router.push("AdminLogin")
                // }
                // else {
                //  console.log("Error is : " + e)
                // }
              })

            this.datatableCount = response.data.length
          // console.log(response.data);
          })
          .catch((e) => {
            console.log('Error is : ' + e)
          // if (e.response.status === 401) {
          //  console.log("Unauthorized")
          //  localStorage.clear();
          //  this.$router.push("AdminLogin")
          // }
          // else {
          //  console.log("Error is : " + e)
          // }
          })
      },

      refreshList () {
        this.retrieveMembers()
      },
      refreshListWithDateInput () {
        this.retrieveMembers()
        this.startDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
        this.endDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
        this.inputName = null
      },

      removeAllMembers () {
        MemberDataService.deleteAll()
          .then((response) => {
            this.datatableCount = response.data.length
            console.log(response.data)
            this.refreshList()
          })
          .catch((e) => {
            if (e.response.status === 401) {
              console.log('Unauthorized')
              localStorage.clear()
              this.$router.push('AdminLogin')
            } else {
              console.log('Error is : ' + e)
            }
          })
      },

      searchNameDate () {
        var data = {
          name: this.inputName,
          startDate: this.startDate,
          endDate: this.endDate,
        }
        if (this.filterDateTextforall == 'all') {
          data.startDate = false
          data.endDate = false
        }
        console.log('Start Date : ' + JSON.stringify(data))
        MemberDataService.findByNameDate(data)
          .then((response) => {
            // this.filterDateTextforall = null
            this.datatableCount = response.data.length
            this.members = response.data.map(this.getDisplayMember)
            console.log('Response is : ' + response.data)
          })
          .catch((e) => {
            if (e.response.status === 401) {
              console.log('Unauthorized')
              localStorage.clear()
              this.$router.push('AdminLogin')
            } else {
              console.log('Error is : ' + e)
            }
          })
      },

      searchDateForStatistics () {
        var data = {
          startDate: this.startDate,
          endDate: this.endDate,
        }
        if (this.filterDateTextforallStatistics == 'all') {
          data.startDate = false
          data.endDate = false
        }
        console.log('Start Date : ' + JSON.stringify(data))
        MemberDataService.findByNameDate(data)
          .then((response) => {
            this.filterDateTextforallStatistics = null
            this.filteredSubscribers = response.data.length
            // this.members = response.data.map(this.getDisplayMember);
            console.log(response.data)
          })
          .catch((e) => {
            if (e.response.status === 401) {
              console.log('Unauthorized')
              localStorage.clear()
              this.$router.push('AdminLogin')
            } else {
              console.log('Error is : ' + e)
            }
          })
      },

      editMember (id, key) {
        if (key === 'viewLicense') {
          this.viewDetailDeleteButtonShow = true
          this.dialogTitle = 'memberList.viewLicense'
          this.dialogText = 'viewLicenseContainer'
          MemberDataService.get(id)
            .then((response) => {
              this.currentMember = response.data
              console.log(this.currentMember)
              this.birthDate.year = this.currentMember.birth.split('-')[0]
              this.birthDate.month = this.currentMember.birth.split('-')[1]
              this.birthDate.date = this.currentMember.birth.split('-')[2]
              console.log(response.data)
              this.dialog = true
            })
            .catch((e) => {
              if (e.response.status === 401) {
                console.log('Unauthorized')
                localStorage.clear()
                this.$router.push('AdminLogin')
              } else {
                console.log('Error is : ' + e)
              }
            })
        } else if (key === 'viewdetails') {
          this.viewDetailDeleteButtonShow = false
          this.dialogTitle = 'memberList.viewDetail'
          this.dialogText = 'viewDetailContainer'
          this.dialog = true
          MemberDataService.get(id)
            .then((response) => {
              this.currentMember = response.data
              console.log(this.currentMember)
              this.birthDate.year = this.currentMember.birth.split('-')[0]
              this.birthDate.month = this.currentMember.birth.split('-')[1]
              this.birthDate.date = this.currentMember.birth.split('-')[2]
              console.log(this.birthDate)
            })
            .catch((e) => {
              if (e.response.status === 401) {
                console.log('Unauthorized')
                localStorage.clear()
                this.$router.push('AdminLogin')
              } else {
                console.log('Error is : ' + e)
              }
            })
        }
      },

      // deleteMember(id) {
      //   MemberDataService.delete(id)
      //     .then(() => {
      //       this.dialog = false;
      //     })
      //     .catch((e) => {
      //       if (e.response.status === 401) {
      //         console.log("Unauthorized")
      //         localStorage.clear();
      //         this.$router.push("AdminLogin")
      //       }
      //       else {
      //         console.log("Error is : " + e)
      //       }
      //     });
      // },

      getDisplayMember (member) {
        return {
          id: member.id,
          key: Math.floor(100000 + Math.random() * 900000),
          name: member.name,
          email: member.email,
          approvalStatus: member.hcpApproval,
        }
      },

      getMember (id) {
        console.log('In Page : ' + id)
        MemberDataService.get(id)
          .then((response) => {
            this.currentMember = response.data
            console.log(response.data)
          })
          .catch((e) => {
            if (e.response.status === 401) {
              console.log('Unauthorized')
              localStorage.clear()
              this.$router.push('AdminLogin')
            } else {
              console.log('Error is : ' + e)
            }
          })
      },

      updateMember () {
        this.$refs.form.validate()
        console.log('Data is : ' + JSON.stringify(this.currentMember))
        this.currentMember.birth = this.birthDate.year + '-' + this.birthDate.month + '-' + this.birthDate.date
        if (this.$refs.form.validate()) {
          MemberDataService.update(this.currentMember.id, this.currentMember)
            .then((response) => {
              if (response.data == 'LicenseNumber Already Exists') {
                this.licenseNumberalreadyExistModal = true
                console.log('LicenseNumber Already Exists')
                return false
              }
              this.dialog = false
              console.log(response.data)
              this.message = 'The member was updated successfully!'
              this.refreshList()
            })
            .catch((e) => {
              if (e.response.status === 401) {
                console.log('Unauthorized')
                localStorage.clear()
                this.$router.push('AdminLogin')
              } else {
                console.log('Error is : ' + e)
              }
            })
        } else {
          this.$refs.form.validate()
          return false
        }
      },

      deleteMember () {
        MemberDataService.delete(this.currentMember.id)
          .then((response) => {
            this.dialog = false
            console.log(response.data)
            this.refreshList()
          })
          .catch((e) => {
            if (e.response.status === 401) {
              console.log('Unauthorized')
              localStorage.clear()
              this.$router.push('AdminLogin')
            } else {
              console.log('Error is : ' + e)
            }
          })
      },

      filterDate (filterDateText) {
        if (filterDateText == 'today') {
          console.log((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString())
          this.startDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
          this.endDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
          this.filterDateTextforall = ''
        } else if (filterDateText == '1week') {
          this.startDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
          this.endDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
          this.startDate = new Date(this.startDate)
          var newstartDate = this.startDate.setDate(this.startDate.getDate() - 7)
          newstartDate = new Date(newstartDate)
          this.startDate = (new Date(newstartDate - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
          console.log(this.startDate)
          this.filterDateTextforall = ''
        } else if (filterDateText == '1month') {
          this.startDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
          this.endDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
          this.startDate = new Date(this.startDate)
          var newstartDate = this.startDate.setMonth(this.startDate.getMonth() - 1)
          newstartDate = new Date(newstartDate)
          this.startDate = (new Date(newstartDate - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
          console.log(this.startDate)
          this.filterDateTextforall = ''
        } else if (filterDateText == '6month') {
          this.startDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
          this.endDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
          this.startDate = new Date(this.startDate)
          var newstartDate = this.startDate.setMonth(this.startDate.getMonth() - 6)
          newstartDate = new Date(newstartDate)
          this.startDate = (new Date(newstartDate - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
          console.log(this.startDate)
          this.filterDateTextforall = ''
        } else if (filterDateText == '1year') {
          this.startDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
          this.endDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
          this.startDate = new Date(this.startDate)
          var newstartDate = this.startDate.setFullYear(this.startDate.getFullYear() - 1)
          newstartDate = new Date(newstartDate)
          this.startDate = (new Date(newstartDate - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
          console.log(this.startDate)
          this.filterDateTextforall = ''
        } else if (filterDateText == 'all') {
          this.startDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
          this.endDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
          this.filterDateTextforall = 'all'
        }
      },

      filterDateforStatistics (filterDateTextforStatistics, flag) {
        if (flag) {
          if (filterDateTextforStatistics == 'today') {
            console.log((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString())
            this.startDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            this.endDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            this.searchDateForStatistics()
          } else if (filterDateTextforStatistics == '1week') {
            this.startDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            this.endDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            this.startDate = new Date(this.startDate)
            var newstartDate = this.startDate.setDate(this.startDate.getDate() - 7)
            newstartDate = new Date(newstartDate)
            this.startDate = (new Date(newstartDate - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            console.log(this.startDate)
            this.searchDateForStatistics()
          } else if (filterDateTextforStatistics == '1month') {
            this.startDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            this.endDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            this.startDate = new Date(this.startDate)
            var newstartDate = this.startDate.setMonth(this.startDate.getMonth() - 1)
            newstartDate = new Date(newstartDate)
            this.startDate = (new Date(newstartDate - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            console.log(this.startDate)
            this.searchDateForStatistics()
          } else if (filterDateTextforStatistics == '6month') {
            this.startDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            this.endDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            this.startDate = new Date(this.startDate)
            var newstartDate = this.startDate.setMonth(this.startDate.getMonth() - 6)
            newstartDate = new Date(newstartDate)
            this.startDate = (new Date(newstartDate - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            console.log(this.startDate)
            this.searchDateForStatistics()
          } else if (filterDateTextforStatistics == '1year') {
            this.startDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            this.endDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            this.startDate = new Date(this.startDate)
            var newstartDate = this.startDate.setFullYear(this.startDate.getFullYear() - 1)
            newstartDate = new Date(newstartDate)
            this.startDate = (new Date(newstartDate - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            console.log(this.startDate)
            this.searchDateForStatistics()
          } else if (filterDateTextforStatistics == 'all') {
            this.startDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            this.endDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            this.filterDateTextforallStatistics = 'all'
            this.searchDateForStatistics()
          }
        } else {
          if (filterDateTextforStatistics == 'today') {
            console.log((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString())
            this.startDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            this.endDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            this.searchDateForStatistics()
          } else if (filterDateTextforStatistics == '1week') {
            this.startDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            this.endDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            this.startDate = new Date(this.startDate)
            var newstartDate = this.startDate.setDate(this.startDate.getDate() - 7)
            newstartDate = new Date(newstartDate)
            this.startDate = (new Date(newstartDate - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            console.log(this.startDate)
            this.searchDateForStatistics()
          } else if (filterDateTextforStatistics == '1month') {
            this.startDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            this.endDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            this.startDate = new Date(this.startDate)
            var newstartDate = this.startDate.setMonth(this.startDate.getMonth() - 1)
            newstartDate = new Date(newstartDate)
            this.startDate = (new Date(newstartDate - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            console.log(this.startDate)
            this.searchDateForStatistics()
          } else if (filterDateTextforStatistics == '6month') {
            this.startDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            this.endDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            this.startDate = new Date(this.startDate)
            var newstartDate = this.startDate.setMonth(this.startDate.getMonth() - 6)
            newstartDate = new Date(newstartDate)
            this.startDate = (new Date(newstartDate - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            console.log(this.startDate)
            this.searchDateForStatistics()
          } else if (filterDateTextforStatistics == '1year') {
            this.startDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            this.endDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            this.startDate = new Date(this.startDate)
            var newstartDate = this.startDate.setFullYear(this.startDate.getFullYear() - 1)
            newstartDate = new Date(newstartDate)
            this.startDate = (new Date(newstartDate - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            console.log(this.startDate)
            this.searchDateForStatistics()
          } else if (filterDateTextforStatistics == 'all') {
            this.startDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            this.endDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            this.filterDateTextforallStatistics = 'all'
            this.searchDateForStatistics()
          }
        }
      },

      memberRegistrationStatistics () {
        this.memberPageSwap = 'memberRegistrationStatistics'
      },

      memberLoginStatistics () {
        this.memberPageSwap = 'memberLoginStatistics'
      },

      logoutAdmin () {
        MemberDataService.logoutAdmin()
          .then((response) => {
            console.log('Logout')
            this.currentMember = ''
            localStorage.clear()
            this.$router.push('AdminLogin')
          })
          .catch((e) => {
            if (e.response.status === 401) {
              console.log('Unauthorized')
              localStorage.clear()
              this.$router.push('AdminLogin')
            } else {
              console.log('Error is : ' + e)
            }
          })
      },
    },
  }
</script>

<style scoped>
button.date_time_week_btngroup_button.btn.btn-outline-secondary.v-btn.v-item--active.v-btn--active.v-btn--is-elevated.v-btn--has-bg.theme--light.v-size--default {
  background-color: black;
  color: white;
}

button.v-btn.v-item--active.v-btn--active.v-btn--is-elevated.v-btn--has-bg.theme--light.v-size--default {
  background-color: black;
  /* color: white !important; */
}

button.v-btn.v-item--active.v-btn--active.v-btn--is-elevated.v-btn--has-bg.theme--light.v-size--default .v-btn__content {
  color: white !important;
}

.email_read_only_modal .v-input__control .v-input__slot {
  background-color: #EDEDED;
}

.v-btn:before {
  background-color: #f5f5f5 !important;
}

.v-text-field input {
  /* padding-top: 8px !important; */
  margin-top: 5px !important;
}

/* .member_text_field_slot v-input__control v-input__slot v-text-field__slot  input{
  padding-top: 8px 0 8px !important;
} */
.list {
  max-width: 750px;
}

.datacountClass {
  font-family: Noto Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}

/* .v-btn {
  min-width: 100px;
} */
.v-input__slot {
  min-height: 0 !important;
}

.admin_dashbord_content_member_list {
  /* margin-top: 3px !important; */
}

.v-input__prepend-outer {
  margin-top: 0px !important;
}

.v-input__append-inner {
  margin-top: 6px !important;
}

.v-text-field input {
  padding-top: 3px !important;
}

table th span {
  font-size: 14px !important;
  font-family: 'Noto Sans' !important;
  color: black;
}

.v-data-table-header {
  background-color: #D9D9D9;
}

/* .admin_dashbord_content_member_list_row {
  margin-top: -23px !important;
  margin-left: 5px !important;
} */

/* .admin_dashbord_content_member_list_row_first {
  margin-left: 5px !important;
} */

.vcardaction_buttons {
  margin: 15px 247px !important;
  /* justify-content: center !important; */
}

.vcardaction_buttons_withdelete {
  margin: 15px 174px !important;
}

.v_dialog_member {
  font-size: 14px !important;
  color: #000000 !important;
  font-weight: 400px !important;
  font-family: 'Noto Sans' !important;
}

li {
  font-size: 14px !important;
  color: #000000 !important;
  font-weight: 400px !important;
  font-family: 'Noto Sans' !important;
}

.approval_toggle_button {
  /* position: absolute !important; */
  width: 849.31px !important;

  background: #FFFFFF !important;
}

.v-dialog.v-dialog--active.v-dialog--persistent.v-dialog--scrollable {
  max-height: 90%;
}

.v-select__slot {
  max-height: 36px !important;
  display: flex !important;
  align-items: center !important
}

.approval_toggle_button button {
  width: 20% !important;
}

.approval_toggle_button button span {
  color: black;
  word-break: break-all !important;
}

.v-btn__content {
  /* word-wrap: break-word; */
}

.name_details_span li {
  list-style-type: none;
}

@media screen and (max-width: 992px) {
  .view_license_dialog {
    min-width: fit-content !important;
  }

}

.view_license_main_footer {
  justify-content: center !important;
  text-align: center;
}

.view_license_main_footer button {
  background-color: #D9D9D9;
  border-radius: 0%;
  border: none;
  width: 150px;
  height: 45px;
  color: black;
}

ul.view_license_dialog .theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
  min-width: 230px;
  margin: 5px;
  /* border: 1px solid black !important; */
  border-radius: 6px;
}

.approval_toggle_button.v-item-group.theme--light.v-btn-toggle {
  display: block;
}

@media only screen and (max-width: 992px) {
  .theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
    /* changed in button group */
    /* display: block; */
  }

  .v-application .d-inline-block {
    display: inline-block !important;
    word-break: break-word;
    width: 50%;
    float: left;
  }

  .v-card__text.v_dialog_member {
    overflow-x: hidden;
  }

  .view_license_dialog.list-group.list-group-horizontal {
    width: 100%;
    float: left;
  }
}

.dialog_member_row_test {
  margin: -22px 0px !important;
}

@media screen and (max-width: 487px) {
  .view_details_close_btn {
    margin-top: 2% !important;
  }

}

@media screen and (min-width: 960px) {
  .date_time_week_btngroup_toggle {
    display: block !important;
  }

}

@media screen and (max-width: 555px) {
  .date_time_week_btngroup_toggle {
    display: block !important;
  }

}

/* .date_time_week_btngroup_button {
  min-width: 0px !important;
  border: none !important;
  border-radius: 5px !important;
  height: 36px !important;
} */

img {
  vertical-align: middle;
  border: 0;
}
</style>
